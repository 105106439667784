<template>
  <div v-if="product" class="flex flex-col sm:flex-row sm:items-center">
    <div class="flex items-center">
      <Money is-bold :value="price" />
      <template v-if="product.cartControl.step !== 1">
        {{ $t('perUnit', { step: product.cartControl.step, unit }) }}
      </template>
    </div>

    <div v-if="priceBefore && priceBefore !== price" class="flex">
      <Money
        class="line-through text-primary-1-60"
        :class="[isLarge ? 'md:text-base' : 'text-sm']"
        :value="priceBefore"
      />

      <template v-if="product.cartControl.step !== 1">
        {{ $t('perUnit', { step: product.cartControl.step, unit }) }}
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ProductNodes, Unpacked } from '@robustastudio/e-commerce/common';

function resolvePricePerStep(price: number, step: number) {
  if (price === 0) {
    return null;
  }
  return price * step;
}
const props = defineProps({
  product: {
    type: Object as PropType<
      Pick<Unpacked<ProductNodes>, 'price' | 'priceBefore' | 'cartControl' | 'type' | 'productsVariants'>
    >,
    default: null,
  },
  isBold: {
    type: Boolean,
    default: false,
  },
  isLarge: {
    type: Boolean,
    default: false,
  },
});

// const { product } = useProductContext();
const { t } = useI18n();

const pricePerStep = computed(() =>
  resolvePricePerStep(Number(props.product.price), Number(props.product?.cartControl.step)),
);
const beforePricePerStep = computed(() =>
  resolvePricePerStep(props.product.priceBefore || 0, Number(props.product?.cartControl.step)),
);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isRangedProduct = computed(() => ['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type));

// price attributes
const unit = computed(() => props.product.cartControl.unit || t('piece').toString());

const price = computed(() => {
  if (['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)) {
    // get the minimum price of all options
    return props.product?.productsVariants?.reduce(
      (accu, option) => (option.price < accu ? option.price : accu),
      Number.MAX_SAFE_INTEGER,
    );
  }
  if (pricePerStep.value && pricePerStep.value !== props.product.price) {
    return pricePerStep.value;
  }

  return props.product.price;
});

const priceBefore = computed(() => {
  if (['GroupedProduct', 'ConfigurableProduct'].includes(props.product.type)) {
    // get the minimum price of all options
    return props.product.productsVariants?.reduce((accu, option) => (option?.price < accu?.price ? option : accu), {
      price: Number.MAX_SAFE_INTEGER,
      priceBefore: 0,
    })?.priceBefore;
  }

  if (beforePricePerStep.value && beforePricePerStep.value !== props.product?.priceBefore) {
    return pricePerStep.value;
  }
  return props.product.priceBefore;
});
</script>

<i18n>
{
    "en": {
        "perUnit": "/ {step} {unit}",
        "piece": "KG",
        "startsFrom": "Starts From"
    },
    "ar": {
        "perUnit": "/ {step} {unit}",
        "piece": "KG",
        "startsFrom": "يبدأ من "
    }
}
</i18n>
