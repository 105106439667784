<template>
  <div v-if="options.length > 1">
    <button
      v-for="(option, index) in options.slice(0, 4).filter(option => option.color)"
      :key="index"
      class="w-6 h-6 p-1 rounded-full border"
      :class="{
        'border-black': selectedOption && selectedOption.slug === option.slug,
        'border-transparent': !(selectedOption && selectedOption.slug === option.slug),
      }"
      :aria-label="option.color || 'color'"
      @mouseenter="selectedOption = option"
    >
      <div class="w-full h-full rounded-full" :style="`background-color: ${option.color}`" />
    </button>
    <div v-if="options.length > 3">+{{ options.slice(3).length }}</div>
  </div>
</template>
<script setup lang="ts">
import type { Options, Unpacked } from '@robustastudio/e-commerce/common';

/**
 * Handle only colors for now
 */
const props = defineProps({
  value: { type: Object as PropType<Unpacked<Options>>, required: false, default: () => ({}) },
  options: {
    type: Array as PropType<Options>,
    required: false,
    default: () => [],
  },
});

const selectedOption = ref(props.value);

watch(
  () => props.value,
  (newValue, oldValue) => {
    if (newValue && oldValue.slug !== newValue.slug) selectedOption.value = newValue;
  },
);
const emit = defineEmits(['option']);
watch(selectedOption, option => {
  emit('option', option);
});
</script>
