<template>
  <div v-if="items.length" class="relative w-full">
    <Swiper
      id="thumb-swiper"
      ref="swiperRefThumb"
      class="thumb-swiper-container swiper-container"
      :slides-per-view="1"
      :space-between="20"
      :centered-slides="true"
      :centered-slides-bounds="true"
      :center-insufficient-slides="true"
      :slides-offset-before="0"
      :touch-move-stop-propagation="true"
    >
      <SwiperSlide v-for="(item, index) in items" :key="index" class="swiper-slide thumb-swiper-slide">
        <slot :item="item" />
      </SwiperSlide>

      <SwiperControls :current-index="selectedIndex" @change="($event: number) => emits('option', $event)" />
    </Swiper>
  </div>
</template>

<script setup lang="ts">
import type { Options } from '@robustastudio/e-commerce/common';
import { Swiper, SwiperSlide } from 'swiper/vue';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const props = defineProps({
  items: {
    type: Array as PropType<Options>,
    required: true,
  },
  slidesPerViewMobile: {
    type: Number,
    default: 1,
  },
  containImages: {
    type: Boolean,
    default: false,
  },
  containVerticalImages: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'primary',
  },
  selectedItem: {
    type: Object,
    default: null,
  },
  selectedIndex: { type: Number, default: 0 },
});

const emits = defineEmits<{
  (event: 'option', option: number): void;
}>();
</script>

<style lang="postcss" scoped>
.thumb-swiper-container {
  width: auto;
}
</style>
