<template>
  <article ref="el" class="ProductCard" @click="productClickEvent">
    <div class="ProductCard__Thumbnail relative flex items-center justify-center rounded-4xl">
      <ProductCardThumb
        class="w-full h-full"
        :value="{
          name: productInternal.name,
          color: '',
          slug: productInternal.slug,
          price: productInternal.price || 0,
          thumb: {
            src: productInternal.thumb.src,
            alt: productInternal.thumb.alt,
          },
        }"
        :items="
          productInternal.options.length > 1
            ? productInternal.options
            : {
                name: productInternal.name,
                color: '',
                slug: productInternal.slug,
                price: productInternal.price || 0,
                thumb: {
                  src: productInternal.thumb.src,
                  alt: productInternal.thumb.alt,
                },
              }
        "
        @option="updateImageOptionDisplay"
      />
      <span
        v-if="productInternal.isBestseller"
        class="block text-sm text-white font-semibold bg-primary-2-70 px-2 md:px-4 md:py-1 absolute top-0 left-0 z-10 rounded-tl-4xl rounded-br-lg"
        >{{ $t('bestseller') }}</span
      >
    </div>

    <div class="ProductCard__Details">
      <div class="flex justify-between gap-x-2.5 w-full">
        <AppLink
          data-attr="productCard_innerPage_link"
          class="flex flex-col items-start"
          :to="`/${productInternal.slug}`"
        >
          <h3 data-attr="productCard_name" class="product-name text-sm font-display hover:underline">
            {{ productInternal.name }}
          </h3>
        </AppLink>

        <FavoriteButton
          :sku="productInternal.sku"
          class="text-primary-1-100 mr-1.5"
          @unfavorite="emit('wishlist-removed')"
        />
      </div>
      <ProductCardPriceSection v-if="productInternal" :product="productInternal" class="flex gap-x-3" />

      <ProductCardOptionsDisplay
        v-if="productInternal.options.length"
        class="flex items-center w-full mb-[14px]"
        :value="productInternal.options && productInternal.options[0]"
        :options="productInternal.options"
        @option="updateImageOptionDisplay"
      />

      <div v-if="isLimitedStock || !productInternal.stock" class="block mt-2 text-sm capitalize">
        <span v-if="isLimitedStock" data-attr="productCard_limitedQty" class="text-primary-2-100">
          {{ $t('limitedQty', { count: productInternal.stock }) }}
        </span>
        <span v-if="!productInternal.stock" data-attr="productCard_outOfStock" class="text-gray-90">
          {{ $t('outOfStock') }}
        </span>
        &nbsp;
      </div>

      <div v-if="discount || productInternal.isNew" class="mt-2 flex items-center gap-x-4 z-10">
        <span
          v-if="discount"
          data-attr="productCard_discount"
          class="text-sm lg:text-base uppercase bg-primary-2-100 font-bold rounded-lg text-white p-1.5 lg:px-3"
        >
          {{ $n(discount, 'percent') }} {{ $t('off') }}
        </span>

        <span
          v-if="productInternal.isNew"
          data-attr="productCard_is-new"
          class="text-base bg-system-green rounded-lg font-bold text-white px-3 py-2"
        >
          {{ $t('isNew') }}
        </span>
      </div>
    </div>
  </article>
</template>

<script setup lang="ts">
import type { Options, ProductNodes, Unpacked } from '@robustastudio/e-commerce/common';
import { clone } from 'lodash-es';

const props = defineProps({
  product: {
    type: Object as PropType<Unpacked<ProductNodes>>,
    default: null,
  },
});

const { limited_qty_threshold } = useStoreConfig();
const { emit } = useEventBus();
const el = ref<Element>();
const observer = ref<IntersectionObserver>();

/**
 * component state
 */

const isLimitedStock = computed(() => {
  return props.product?.stock && limited_qty_threshold.value >= props.product?.stock;
});

const discount = computed(() => {
  if (!props.product?.priceBefore) {
    return 0;
  }

  // round to the nearest multiple of 5
  // discount is between 0 and 1
  return (
    (Math.round((((props.product?.priceBefore - props.product?.price) / props.product?.priceBefore) * 100) / 5) * 5) /
    100
  );
});

const isInComparison = useIsInComparison(props.product?.sku || '');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function toggleComparison() {
  if (isInComparison.value) {
    removeItem(props.product?.sku || '');
    return;
  }

  compareItem({
    sku: props.product?.sku || '',
    categoryIds: props.product?.categories?.map(c => c?.id || 0) || [],
  });
}

const productInternal = ref(clone(props.product));

onMounted(() => {
  // Send product impression event only if card is above the fold
  observer.value = new IntersectionObserver(
    entries => {
      if (entries[0].intersectionRatio === 1) {
        observer.value?.disconnect();
      }
    },
    {
      threshold: [1.0],
    },
  );
  if (el.value) observer.value.observe(el.value as Element);
});

onBeforeUnmount(() => {
  observer.value?.disconnect();
});

/**
 * Function that handles triggering product_click event for Enhanced Ecommerce
 */
const { productClick } = useGtmEvents();

function productClickEvent() {
  productClick(props.product);
}
function updateImageOptionDisplay(option?: Unpacked<Options>) {
  if (!option) {
    productInternal.value = { ...props.product };

    return;
  }

  productInternal.value = { ...props.product, ...option };
}

const { t: $t } = useI18n({
  useScope: 'local',
});

/**
 *
 * A Computed Property to determine whether we are able to deal with add item to the cart or not from here
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ableToAddToCart = computed<boolean>(() => {
  return productInternal.value.type === 'SimpleProduct';
});
</script>

<style lang="postcss" scoped>
.ProductCard {
  @apply relative h-full flex flex-col;

  &__Thumb {
    width: 70px;
    height: 70px;

    @apply object-cover transition-transform duration-300 ease-in-out transform;
  }

  &__Badges {
    @apply absolute left-0 flex items-start w-full text-sm z-10;
    top: 10px;
  }

  &__Details {
    @apply mt-6 flex flex-col h-full;
    transition: border-color 0.3s ease-in-out;
  }

  &:hover {
    .ProductCard__Details {
      @apply border-primary-1-100;
    }
  }

  &__compare {
    transition:
      transform 0.2s ease-in-out,
      color 0.2s ease-in-out,
      background-color 0.2s ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
}

.z-1 {
  z-index: 1;
}

.product-name {
  min-height: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fade-out {
  transition: opacity 0.4s ease-in;
}
.fade-in {
  transition: opacity 0.4s ease-out;
}
</style>

<i18n>
{
  "en": {
    "off": "Off",
    "specialOffer": "Special Offer",
    "startsFrom": "Starts from",
    "limitedQty":"Only {count} Left",
    "outOfStock": "Out of Stock",
    "compare": "Compare Product",
    "goToOptions": "Multiple Options Available",
    "isNew": "New",
    "addToCart": "Add To Cart",
    "rating": " {rating}/5",
    "notFound": "NA",
    "bestseller": "Bestseller"
  },
  "ar": {
    "off": "خصم",
    "specialOffer": "عرض خاص",
    "startsFrom": "يبدأ من",
    "limitedQty":"كمية محدودة",
    "outOfStock": "نفذت الكمية",
    "compare": "مقارنه المنتج",
    "goToOptions": "الخيارات",
    "isNew": "جديد",
    "addToCart": "أضف الى السله",
    "notFound": "غير موجود",
    "bestseller": "الأكثر مبيعاً"
  }
}
</i18n>
