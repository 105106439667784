<template>
  <button
    data-cy="add-to-wishlist"
    title="Add to wishlist"
    type="button"
    class="ProductCard__favorite rounded-xs w-6 h-5 [ flex items-center justify-center ]"
    @click="toggleWishlist"
  >
    <svg-icon-favorite-filled v-if="isFavorited" class="w-full h-full" />
    <svg-icon-favorite v-else class="w-full h-full" />

    <slot />
  </button>
</template>

<script setup lang="ts">
import type { ProductData } from '@robustastudio/e-commerce/common';
import { debounce } from 'lodash-es';

const props = defineProps({
  sku: {
    type: String,
    required: true,
  },
  product: {
    type: Object as PropType<ProductData>,
    required: false,
    default: () => ({}),
  },
});
const isFavorited = ref(false);
// const { emit: emitEvent } = useEventBus();
const { user } = useAuth();
const isInWishlist = useIsInWishlist(props.sku);

watch(
  isInWishlist,
  value => {
    isFavorited.value = value;
  },
  {
    immediate: true,
  },
);
const emit = defineEmits(['unfavorite']);
const { t: $t } = useI18n({ useScope: 'local' });
const { warn } = useAlerts();
const { redirect } = useAppRouter();
const { addToWishlist } = useAddToWishlist(props.sku);
const { removeFromWishlist } = useRemoveFromWishlist(props.sku);
// Ensures we don't call too many requests
const commitToggle = debounce(() => {
  if (!user.value) {
    warn($t('loginFirst').toString());
    redirect('/login');
    return;
  }
  if (isFavorited.value) {
    // props.product && emitEvent(TRACKING_EVENTS.ADD_TO_WISH_LIST, props.product);
    return removeFromWishlist().then(() => {
      emit('unfavorite');
    });
  }
  return addToWishlist();
}, 500);

function toggleWishlist() {
  commitToggle();
}
</script>

<style lang="postcss" scoped>
.ProductCard__favorite {
  @apply flex items-center;
  transition: transform 0.2s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }

  svg.is-active path {
    @apply fill-current text-system-red;
  }
}
</style>

<i18n>
{
  "en": {
    "loginFirst": "Oops! You need to log in."
  },
  "ar": {
    "loginFirst": "عفوا! تحتاج إلى تسجيل الدخول"
  }
}
</i18n>
