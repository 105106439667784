<template>
  <div>
    <AppLink v-if="!Array.isArray(items)" :to="`/${value.slug}`">
      <picture>
        <AppSource :srcset="value.thumb.src" format="avif" media="(min-width: 768px)" width="620" height="911" />
        <AppSource :srcset="value.thumb.src" format="webp" media="(min-width: 768px)" width="620" height="911" />
        <AppSource :srcset="value.thumb.src" format="jpeg" media="(min-width: 768px)" width="620" height="911" />

        <AppSource :srcset="value.thumb.src" format="avif" media="(max-width: 767px)" width="620" height="911" />
        <AppSource :srcset="value.thumb.src" format="webp" media="(max-width: 767px)" width="620" height="911" />
        <AppSource :srcset="value.thumb.src" format="jpeg" media="(max-width: 767px)" width="620" height="911" />

        <AppImage
          data-attr="productCard_productImage"
          class="ProductCard__Thumb"
          :src="value.thumb.src"
          :alt="value.thumb.alt"
          loading="lazy"
        />
      </picture>
    </AppLink>

    <ProductCardThumbSlider
      v-else
      v-slot="{ item }"
      :items="items"
      :selected-index="selectedThumb"
      class="flex-1"
      @option="(index: number) => emit('option', Array.isArray(items) ? items[index] : items)"
    >
      <AppLink :to="`/${item.slug}`" class="block w-full">
        <picture>
          <AppSource :srcset="value.thumb.src" format="avif" media="(min-width: 768px)" width="620" height="911" />
          <AppSource :srcset="value.thumb.src" format="webp" media="(min-width: 768px)" width="620" height="911" />
          <AppSource :srcset="value.thumb.src" format="jpeg" media="(min-width: 768px)" width="620" height="911" />

          <AppSource :srcset="value.thumb.src" format="avif" media="(max-width: 767px)" width="620" height="911" />
          <AppSource :srcset="value.thumb.src" format="webp" media="(max-width: 767px)" width="620" height="911" />
          <AppSource :srcset="value.thumb.src" format="jpeg" media="(max-width: 767px)" width="620" height="911" />

          <AppImage
            data-attr="productCard_productImage"
            class="ProductCard__Thumb"
            :src="value.thumb.src"
            :alt="value.thumb.alt"
            loading="lazy"
          />
        </picture>
      </AppLink>
    </ProductCardThumbSlider>
  </div>
</template>
<script setup lang="ts">
import type { Options, Unpacked } from '@robustastudio/e-commerce/common';
const props = defineProps({
  value: {
    type: Object as PropType<Unpacked<Options>>,
    required: false,
    default: () => ({}),
  },
  items: {
    type: [Array, Object] as PropType<Options | Unpacked<Options>>,
    default: () => [],
  },
});

const emit = defineEmits(['option']);
const selectedThumb = ref();

watch(
  () => props.value,
  newValue => {
    if (Array.isArray(props.items))
      selectedThumb.value = (props.items as Options).findIndex(item => item.slug === newValue.slug);
  },
);
</script>

<style lang="postcss" scoped>
.ProductCard__Thumb {
  @apply object-cover rounded-4xl transition-transform duration-300 ease-in-out transform;
  width: 100%;
  aspect-ratio: 0.68;
}
</style>
